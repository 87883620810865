import type { FetchError } from '~/api/utils/types';

function getFetchErrorMessage<T extends FetchError>(
  error: T,
): string | undefined;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message: M,
): string;

function getFetchErrorMessage<T extends FetchError, M extends string>(
  error: T,
  message?: M,
) {
  return (
    (process.env.NODE_ENV !== 'production'
      ? error.body?.data?.message
      : error.body?.message) || message
  );
}

export default getFetchErrorMessage;
